<template>
  <div>
    <div class="DivBox">
      <p class="PTitle">ISO9001质量管理体系审核</p>
    </div>
    <div class="DivBox">
      <p class="PTitle LeftBor">服务介绍：</p>
      <p class="SmallP" style="text-indent: 2em">
        ISO9001质量管理体系，是ISO（国际标准化组织）制定的一套关于质量管理的标准，为企业的质量管理提供一套成熟有效的管理模式。
        ISO9001每年必须由认证机构进行一次审核以证明企业的管理体系有持续运行。在认证机构审核前，企业应进行一次内部审核。
        我司经验丰富的咨询顾问将现场指导企业内部审核，其意义在于：
      </p>
      <div class="PList">
        <p>①发现体系运行缺陷，确保顺利通过认证机构审核。</p>
        <p>②为企业持续有效运行体系提供建设性建议。</p>
        <p>③提高企业员工体系维护的能力。</p>
      </div>
      <br />
      <br />
      <p class="PTitle">我们的优势</p>
      <p class="SmallP" style="text-indent: 2em">
        我司成立于1997年，专业从事国际标准管理体系咨询和培训业务，20多年来服务的客户中不乏世界500强企业、大型港台企业、著名的上市公司和服务公司等，客户类型覆盖广泛，目前已成为省内客户及整合一体化体系最多的咨询公司。
      </p>
      <br />
      <br />
      <p class="PTitle">管理体系内部审核步骤：</p>
      <br />
      <img
        @click="openWindow"
        src="@/assets/FirstPage-ProductSon/60.png"
        class="SonImgW"
      />
      <br />
      <br />
      <br />
      <p class="PTitle LeftBor">ISO9001管理体系审核重点:</p>
      <div class="PList" style="margin-top: 10px">
        <p>1. 质量管理体系“过程方法”的应用。</p>
        <p>2. 质量风险的评价与应对措施。</p>
        <p>3. 质量目标与实施方案。</p>
        <p>4. 质量管理法律法规的识别与合规性。</p>
        <p>5. 质量管理标准的策划与有效实施。</p>
        <p>6. 质量管理标准的策划与有效实施。</p>
        <p>7. 顾客要求的有效落实。</p>
        <p>8. 顾客满意度的达成情况。</p>
        <p>9. 不合格的纠正措施。</p>
        <p>10. 外部供方的绩效。</p>
        <p>11. .....</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    openWindow() {
      window.open("https://www.yuntixi.cn", "_blank");
    },
  },
};
</script>

<style>
@import "../../../../assets/style/FirstProductSon.css";
</style>